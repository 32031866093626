
body {
  font-family: Mogra;
  background-color:lightblue;
  background-image:url(../src/Ice-Cream-Wallpaper-Desktop-1068x601.jpg);
  background-size:cover;
  background-position:center center;
}
header h1{
  display: flex;
  justify-content: center;
  text-shadow:black 6px 4px 4px;
  color:lightcoral;
  text-size:larger;
}
.navigationBar {
  font-size:larger;
  margin:20px;
  flex-direction:row;
  
}

figure {
  display:flex;
}
figure img {
float:left;
width:400px;
height:400px;
object-fit:cover;
margin-right:200px;
box-shadow:20px 20px 50px grey;
border-radius:8px;
}
.name {
  font-size:50px;
  color:lightcoral;
  text-align:center;
  text-shadow:black 6px 4px 4px;
}
.description {
  font-size:20px;
 backdrop-filter:blur(10px);
  text-align:center;
  margin-left:10px;
  line-height:3rem;
  
}
  .favFlavor {
  
    color:darkslategrey;
    font-size:25px;
  
  }

  .location {
    
    color:darkslategrey;
    font-size:25px;
    margin:0;

  }
.inline {
display:inline-block;
text-align:center;
box-shadow:20px 20px 50px grey;
outline:inset lightcoral;
outline-width:5px;
border-radius:8px;

}
   
  
.detailsContainer {
  display:grid;
  grid-template-columns: 1 fr 1fr;
  backdrop-filter:blur(10px);
  border-radius:8px;
  
}
 #contact {
  display:flex;
  justify-content:center;
 }


.searchbar {
  display:block;
  margin-left:400px;
}
#search{
  width:400px;
  
}
input#search{
  background-color:moccasin;
}

.newReview{
  
  text-shadow:black 6px 4px 4px;
  color:lightcoral;
  font-size: 25px;
  margin-left:80px;
}
.newReview textarea{
  width:400px;
  margin-left:400px;
  border-radius:5px;
 padding:1rem;
 font-size:20px;
 
 
}
.newReview h1{
  margin-left:470px;
}
.newReview button{
  padding:10px;
}
  

.shops {
  list-style:none;
 
}


.card {
margin:1rem;
margin-left:80px;

max-width: 200px;
display:inline-grid;
box-shadow:20px 20px 50px grey;
padding: 1rem;
background-color:lightcoral;
border-radius:8px;
justify-self:center;
}


.card img {
  width: 100%;
  height: 175px;
  object-fit: cover;
}

button {
  background-color: moccasin;
  cursor:pointer;
  font-family:'Trebuchet MS' sans-serif;
  border-radius:8px;
}

  .new-shop-form h2{
    text-shadow:black 6px 4px 4px;
    color:lightcoral;
    font-size: 75px;
    margin-left:80px;
  }


  

.new-shop-form input {
 display:flex;
 border-radius:8px;
 padding:10px 10px 10px 10px;
 width:50%;
 margin-top: 25px;

 margin-left:200px;

}
.new-shop-form select {
  width:52%;
  border-radius:8px;
  padding:10px;
  margin-top:25px;
  margin-left:200px;
}
.new-shop-form button {
  float:right;
  margin-right:400px;
  margin-top:25px;
  padding:15px;
  box-shadow:20px 20px 50px grey;
}

.new-shop-form button:hover{
 background-color:rgb(208, 153, 58)
 
}


.new-shop-form h2{
  display:flex;
  margin-left:350px;
}